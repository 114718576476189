<template>
  <router-link to="/" class="logo-link">
    <img class="logo-light logo-img logo-img-lg" :src="appLogo" :srcset="require('@/assets/images/logos/logo2x.png')+' 2x'" alt="logo">
    <img class="logo-dark logo-img logo-img-lg" :src="appLogoDark" :srcset="require('@/assets/images/logos/logo-dark2x.png')+' 2x'" alt="logo-dark">
  </router-link>
</template>

<script>
import {$themeConfig} from "../../../../themeConfig";

const { appLogo, appLogoDark} = $themeConfig.app;
export default {
  setup(){
    return {
      appLogo,
      appLogoDark,
    }
  }
}
</script>
