<template>
  <ion-page>
    <ion-content :fullscreen="true" class="bg-lighter ion-content">
      <div class="nk-wrap nk-wrap-nosidebar">
        <!-- content @s -->
        <div class="nk-content">
          <div class="nk-block nk-block-middle nk-auth-body wide-sm mt-0">

            <!-- -->
            <registration-nav-steps :current-step="currentStep"></registration-nav-steps>

            <div class="brand-logo pb-4 text-center">
              <brand-logo class="d-none"></brand-logo>
            </div>
            <div class="card shadow-lg">
              <div class="card-inner card-inner-lg">
                <reg-step1 v-if="currentStep === 1" @goto-step="gotoStep"></reg-step1>
                <reg-step2 v-else-if="currentStep === 2" @goto-step="gotoStep" @register-advisor="registerAdvisor"></reg-step2>
                <reg-step3 v-else-if="currentStep === 3" @goto-step="gotoStep" :e-curring-mandate-signing-url="eCurringMandateSigningURL"></reg-step3>
              </div>
            </div>
          </div>
          <auth-footer full-width></auth-footer>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>
<script>

import axios from "@/libs/axios"
import { useStore } from "vuex"
import {ref, defineComponent} from "vue";
import BrandLogo from "@core/components/logo/BrandLogo";
import RegistrationNavSteps from "@/views/auth/components/RegistrationNavSteps";
import RegStep1 from "@/views/auth/components/RegStep1";
import RegStep2 from "@/views/auth/components/RegStep2";
import RegStep3 from "@/views/auth/components/RegStep3";
import AuthFooter from "@/views/auth/components/AuthFooter";
import {loadingController, toastController, IonPage, IonContent, alertController} from "@ionic/vue"

export default defineComponent({
  components: {
    AuthFooter,
    BrandLogo,
    RegistrationNavSteps,
    RegStep3,
    RegStep2,
    RegStep1,
    IonPage,
    IonContent,
  },
  setup(){

    const store = useStore()
    let currentStep = ref(1)
    let eCurringMandateSigningURL = ref('')

    const gotoStep = (step) => currentStep.value = step
    const registerAdvisor = async () =>{

      let loader = await loadingController.create({message: "Please wait..."})
      await loader.present()

      let userData = Object.assign({}, store.state.advisor_reg.registration_data)


      userData.client_id = process.env.VUE_APP_API_CLIENT_ID
      userData.client_secret = process.env.VUE_APP_API_CLIENT_SECRET

      let axiosConfig = {}
      if( userData.dp_file && userData.dp_file.name ){
        let formData = new FormData()
        for ( let key in userData ) {
          formData.append(key, userData[key])
        }
        userData = formData
        axiosConfig = {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      }

      axios.post('/api/advisor/register', userData, axiosConfig)
          .then(resp => {
            if( resp.data.error ){
              alertController.create({header: "Error", message: resp.data.error, buttons:['OK']}).then((a) => a.present())
            }
            else{
              if(resp.data.access_token){
                store.commit('auth/AUTH_SUCCESS', resp.data)
                localStorage.setItem('access_token', resp.data.access_token)
                localStorage.setItem('refresh_token', resp.data.refresh_token)
                localStorage.setItem('user_role', resp.data.role)
                gotoStep(3)

                setTimeout(signMandate, 1000)
              }
            }
          })
          .catch( error => {
            console.log('Advisor registration ajax error: '+error)
          })
          .then(() => loader.dismiss())
    }
    const signMandate = async () => {

      let loader = await loadingController.create({message: "Please wait..."})
      await loader.present()

      axios.get('/api/advisor/sign_mandate')
          .then(resp => {
            if( !resp.data.success){
              toastController.create({color: "danger", message: resp.data.message, duration: 3000}).then( toast => toast.present())
            }
            else{
              eCurringMandateSigningURL.value = resp.data.data.url
            }
          })
          .catch(error => {
            toastController.create({color: "danger", message: 'Something went wrong', duration: 2500}).then( toast => toast.present())
            console.log('Mandate signing ajax error: '+error)
          })
          .then(()=> loader.dismiss())
    }

    return {
      currentStep,
      gotoStep,
      registerAdvisor,
      eCurringMandateSigningURL,
    }
  },
})
</script>
