<template>
  <div id="reg_step_2">
    <div class="nk-block-head text-center">
      <div class="nk-block-head-content">
        <h4 class="nk-block-title m-0 fw-bold nk-text-dark">{{ $t('Your public profile') }}</h4>
        <div class="nk-block-des mt-1 mb-4">
          <p class="fw-500 nk-text-muted small">{{ $t('This information will be visible to your clients and used throughout the app') }}.</p>
        </div>
        <div>
          <user-card-variant2
              :user-name="step2Data.first_name+' '+step2Data.last_name"
              :user-email="step2Data.email"
              :phone="step2Data.phone_no"
              :post-code="step2Data.postcode.length ? step2Data.postcode : '- - - - -'"
              :city="step2Data.house_no.length ? step2Data.house_no : '- - - - - - - - '"
              :company="step2Data.company_name.length ? step2Data.company_name : '- - - - - - - - '"
              province=""
              :initials="step2Data.first_name.substr(0,1)+step2Data.last_name.substr(0,1)"
              :image="step2Data.dp"
              @on-image-change="onImageChange"
          >

          </user-card-variant2>
        </div>
      </div>
    </div>

    <form action="" onsubmit="return false">
      <form-group>
        <row>
          <column md="6" class="mb-3 mb-md-0">
            <label class="form-label" for="c_name">{{ $t('Company name') }}</label>
            <b-input
                type="text"
                size="lg"
                id="c_name"
                v-model="step2Data.company_name"/>
          </column>
          <column md="6" class="mb-3 mb-md-0">
            <row>
              <column lg="6">
                <label class="form-label" for="postcode">Postcode</label>
                <b-input
                    type="text"
                    size="lg"
                    id="postcode"
                    placeholder="5542 AW"
                    v-model="step2Data.postcode"/>
              </column>
              <column lg="6">
                <label class="form-label" for="house_no">{{ $t('House number') }}</label>
                <b-input
                    type="text"
                    size="lg"
                    id="house_no"
                    placeholder="174"
                    v-model="step2Data.house_no"/>
              </column>
            </row>
          </column>
        </row>
      </form-group>
      <form-group>
        <row>
          <column md="6" class="mb-3 mb-md-0">
            <label class="form-label" for="ccn">{{ $t('Chamber of commerce number') }}</label>
            <b-input
                type="text"
                size="lg"
                id="ccn"
                placeholder="******"
                v-model="step2Data.ch_commerce_no"/>
          </column>
          <column md="6" class="mb-3 mb-md-0">
            <label class="form-label" for="afm">{{ $t('AFM number') }}</label>
            <b-input
                type="text"
                size="lg"
                id="afm"
                placeholder="******"
                v-model="step2Data.afm_no"/>
          </column>
        </row>
      </form-group>
      <form-group class="mt-4">
        <button class="btn btn-lg btn-dark btn-block" @click="validateStep2Data">To Step 3</button>
      </form-group>
    </form>
    <div class="mt-4">
      <nk-button type="dark" dim size="xs" class="px-3" @click="$emit('gotoStep',1)">
        <nio-icon icon="ni-arrow-left" class="mr-2"></nio-icon> Back to step 1
      </nk-button>
    </div>
  </div>
</template>

<script>
import UserCardVariant2 from "@/views/components/user-cards/UserCardVariant2";
import {Column, FormGroup, Row } from "@core/layouts"
import {useStore} from "vuex";
import BInput from "@core/components/bp-form/components/BInput";
import { toastController } from "@ionic/vue"

export default {
  name: "RegStep2",
  components: {
    BInput,
    Column,
    FormGroup,
    Row,
    UserCardVariant2,
  },
  setup(props, {emit}){

    const store = useStore()
    let step2Data = store.state.advisor_reg.registration_data
    const validateStep2Data = () => {
      if(step2Data.company_name.length < 1 || step2Data.postcode.length < 1 || step2Data.house_no.length < 1
      || step2Data.ch_commerce_no.length < 1 || step2Data.afm_no.length < 1){
        toastController.create({color: 'danger', message: 'All fields are required', duration:2000}).then( toast => toast.present())
      }
      else{
        emit('registerAdvisor')
      }
    }

    const onImageChange = (file) => {
      step2Data.dp_file = file
    }

    return {
      step2Data,
      validateStep2Data,
      onImageChange,
    }
  }
}
</script>
