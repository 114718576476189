<template>
  <div id="reg_step_3">
    <div class="nk-block-head text-center">
      <div class="nk-block-head-content">
        <h4 class="nk-block-title m-0 fw-bold nk-text-dark">{{ $t('Sign mandate') }}</h4>
        <div class="nk-block-des mt-1 mb-4">
          <p class="fw-500 nk-text-muted small">{{ $t('To purchase a lead subscription and use our CRM, you must sign a direct debit mandate') }}.</p>
        </div>
      </div>
    </div>
    <div class="mb-3 mt-4">
      <a :href="eCurringMandateSigningUrl" class="btn btn-lg btn-dark btn-block">{{ $t('Sign mandate') }}</a>
    </div>
    <div class="mt-4">
<!--      <nk-button type="dark" dim size="xs" class="px-3" @click="$emit('gotoStep', 2)">
        <nio-icon icon="ni-arrow-left" class="mr-2"></nio-icon> Back to step 2
      </nk-button>-->
    </div>
  </div>
</template>

<script>
export default {
  name: "RegStep3",
  props: {
    eCurringMandateSigningUrl: String,
  }
}
</script>
