<template>
  <div id="reg-steps-wrapper" class="container-fluid">
    <ul class="wizard-nav d-flex flex-wrap text-center">
      <li class="flex-grow-1 align-items-center" v-for="x in 3" :key="x" :class="{completed: (currentStep > x)}">
        <button class="step-circle" :class="{active: (currentStep === x), completed: (currentStep > x)}">
          <nio-icon icon="ni-check" v-if="currentStep > x"></nio-icon>
          <span v-else>{{ x }}</span>
        </button>
        <span class="d-block fw-500">{{ $t(stepsNames[x-1]) }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "RegistrationNavSteps",
  props: {
    currentStep: Number,
  },
  data(){
    return {
      stepsNames: ['Data', 'Profile', 'Mandate'],
    }
  },
}
</script>

<style scoped>
.step-circle{
  font-size: 24px;
  border: none;
  border-radius: 50%;
  font-weight: 600;
  width: 50px;
  height: 50px;
  background-color: #d8d6de;
  position: relative;
  color: #fff;
}
.step-circle.active{
  background-color: #2c1d42;
}
.step-circle.completed{
  background-color: #00c58c;
}
li{
  position: relative;
}
li::after {
  content: " ";
  width: 0;
  height: 5px;
  background: #00c58c;
  position: absolute;
  top: 39%;
  transform: translateY(-50%);
  z-index: -1;
  transition: all 0.5s ease;
}
li.completed::after{
  width: 100%;
}
li::before {
  content: " ";
  width: 100%;
  height: 5px;
  background: #eceef3;
  position: absolute;
  top: 39%;
  transform: translateY(-50%);
  z-index: -1;
}
ul.wizard-nav li:last-child::after,
ul.wizard-nav li:last-child::before{
  content: unset;
}
</style>
