<template>
  <span class="dot" :class="classesList" :style="{background: color}"></span>
</template>

<script>

export default {
  props: {
    color: String,
    size: String,
    square: {
      type: Boolean
    },
  },
  setup(props){
    let classesList = []
    if(props.size){
      classesList.push(`dot-${props.size}`)
    }
    if(props.square){
      classesList.push('sq')
    }
    return {
      classesList,
    }
  }
}
</script>
