export default () => {

    const el = document.getElementById('auth2_script_id');
    if (!el) {
        let gScript = document.createElement('script')
        gScript.setAttribute('src', 'https://apis.google.com/js/platform.js?onload=onGapiLoad')
        gScript.setAttribute("async", true)
        gScript.setAttribute("defer", "defer")
        gScript.setAttribute("id", "auth2_script_id")
        document.head.appendChild(gScript);
    }
    window.onGapiLoad = () => {
        window.gapi.load('auth2')
    }

    const init = async () => {
        let tries = 0;
        while (tries < 5){
            if(undefined !== window.gapi){
                break;
            }
            await timeout(1500)
            tries++
        }
        return window.gapi.auth2.init({
            client_id: process.env.VUE_APP_GOOGLE_CLIENT_ID,
        })
    }

    function timeout(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }
    return {
        init,
    }
}
