<template>
  <div id="reg_step_1">
    <div class="nk-block-head text-center">
      <div class="nk-block-head-content">
        <h4 class="nk-block-title m-0 fw-bold nk-text-dark">{{ $t('Register as an advisor') }}</h4>
        <template v-if="!registrationData.google_id.length && googleAuthLoaded">
          <div class="nk-block-des mt-1 mb-2">
            <p class="fw-500 nk-text-muted small">{{ $t('We advise to sign in using Google to speed up the process') }}</p>
          </div>
          <nk-button type="light" outline class="w-65 text-center justify-content-center" v-on:click="signInWithGoogle">
            <img :src="require('@/assets/images/logos/g.png')" alt="Google" style="width: 24px" class="mr-2"/> {{ $t('Login with Google') }}
          </nk-button>
        </template>
      </div>
    </div>
    <div class="separator text-muted mb-2" v-if="!registrationData.google_id.length && googleAuthLoaded">&ensp;&ensp;or&ensp;&ensp;</div>
    <form action="" @submit.prevent="">
      <form-group>
        <row>
          <column md="6" class="mb-3 mb-md-0">
            <label class="form-label" for="f_name">{{ $t('First name') }}</label>
            <input type="text" class="form-control form-control-lg" id="f_name" placeholder="John" v-model="registrationData.first_name">
          </column>
          <column md="6" class="mb-3 mb-md-0">
            <label class="form-label" for="l_name">{{ $t('Last name') }}</label>
            <input type="text" class="form-control form-control-lg" id="l_name" placeholder="Doe" v-model="registrationData.last_name">
          </column>
        </row>
      </form-group>
      <form-group>
        <row>
          <column md="6" class="mb-3 mb-md-0">
            <label class="form-label" for="password">{{ $t('Password') }}</label>
            <div class="form-control-wrap">
              <a href="javascript:;" class="form-icon form-icon-right passcode-switch" data-target="password"
                 @click="togglePasswordVisibility(1, !password1Visible)">
                <nio-icon icon="ni-eye" v-if="!password1Visible"></nio-icon>
                <nio-icon icon="ni-eye-off" v-else></nio-icon>
              </a>
              <input :type="password1Visible ? 'text': 'password'"
                     class="form-control form-control-lg"
                     id="password" placeholder="********"
                     v-model="registrationData.password"/>
            </div>
          </column>
          <column md="6" class="mb-3 mb-md-0">
            <label class="form-label" for="r_password">{{ $t('Repeat password') }}</label>
            <div class="form-control-wrap">
              <a href="javascript:;" class="form-icon form-icon-right passcode-switch" data-target="password"
                 @click="togglePasswordVisibility(2, !password2Visible)">
                <nio-icon icon="ni-eye" v-if="!password2Visible"></nio-icon>
                <nio-icon icon="ni-eye-off" v-else></nio-icon>
              </a>
              <input :type="password2Visible ? 'text': 'password'"
                     class="form-control form-control-lg"
                     id="r_password"
                     placeholder="********"
                     v-model="registrationData.c_password"/>
            </div>
          </column>
        </row>
      </form-group>
      <form-group>
        <row>
          <column md="6" class="mb-3 mb-md-0">
            <label class="form-label" for="email">{{ $t('Email') }}</label>
            <input type="email" class="form-control form-control-lg" id="email" placeholder="johndoe@gmail.com" v-model="registrationData.email">
          </column>
          <column md="6" class="mb-3 mb-md-0">
            <label class="form-label" for="phone">{{ $t('Phone number') }}</label>
            <input type="text" class="form-control form-control-lg" id="phone" placeholder="06123456789" v-model="registrationData.phone_no">
          </column>
        </row>
      </form-group>
      <form-group class="mt-4">
        <nk-button type="dark" size="lg" class="btn btn-lg btn-dark btn-block" @click="validateStep1Data()">To Step 2</nk-button>
      </form-group>
    </form>
    <div class="form-note-s2 text-center pt-4 fw-500 text-muted">{{ $t('Already have an account?') }} <router-link :to="{name: 'Login'}">Log in</router-link></div>
  </div>
</template>

<script>
import {Column, FormGroup, Row} from "@core/layouts"
import {ref} from "vue";
import { useStore } from "vuex"
import { toastController, /*loadingController*/ } from "@ionic/vue"
import useGoogleSignIn from "@/libs/login-with-google"

export default {
  components: {
    Column,
    FormGroup,
    Row,
  },
  setup(props, {emit}){

    const store = useStore()
    let registrationData = store.state.advisor_reg.registration_data

    let password1Visible = ref(false)
    let password2Visible = ref(false)

    const togglePasswordVisibility = (oneOrTwo, status) => (oneOrTwo === 1 ? password1Visible.value = status : password2Visible.value = status)
    const validateStep1Data = () => {

      let email_reg = /^\S+@\S+$/;
      if(registrationData.first_name.length < 1 || registrationData.last_name.length < 1){
        toastController.create({color: 'danger', message: 'First & last name is required', duration:2000}).then( toast => toast.present())
      }
      else if(registrationData.password.length < 8 ){
        toastController.create({color: 'danger', message: 'Password must be at least 8 characters long', duration:2000}).then( toast => toast.present())
      }
      else if( registrationData.password !== registrationData.c_password){
        toastController.create({color: 'danger', message: 'Both password must match', duration:2000}).then( toast => toast.present())
      }
      else if( !email_reg.test( registrationData.email)){
        toastController.create({color: 'danger', message: 'Provide a valid email address', duration:2000}).then( toast => toast.present())
      }
      else if( registrationData.phone_no.length < 9){
        toastController.create({color: 'danger', message: 'Provide a valid phone number', duration:2000}).then( toast => toast.present())
      }
      else{
        emit('gotoStep', 2)
      }
    }

    let googleAuthLoaded = ref(false)

    const signInWithGoogle = async () => {
      auth2.signIn();
    }

    const onGoogleSignIn = (signedIn) => {
      //console.log(auth2.currentUser.get().getId())
      if(signedIn && !registrationData.first_name.length){
        const authRep = auth2.currentUser.get().getAuthResponse()
        registrationData.google_id_token = authRep.id_token
        const user = auth2.currentUser.get().getBasicProfile()
        console.log('user', user)
        registrationData.first_name = user.getGivenName()
        registrationData.last_name  = user.getFamilyName()
        registrationData.email      = user.getEmail()
        registrationData.google_id  = user.getId()
        registrationData.dp         = user.getImageUrl()
      }
    }

    const { init } = useGoogleSignIn()
    let auth2 = undefined
    init().then( auth => {
      googleAuthLoaded.value = undefined !== auth
      auth2 = auth
      if(undefined !== auth){
        if(auth.isSignedIn.get()){
          onGoogleSignIn(true)
        }
        else{
          auth.isSignedIn.listen(onGoogleSignIn);
        }
      }
    })
    .catch()

    return {
      googleAuthLoaded,
      togglePasswordVisibility,
      password1Visible,
      password2Visible,
      registrationData,
      validateStep1Data,
      signInWithGoogle,
    }
  }
}
</script>

<style scoped>
.separator {
  display: flex;
  align-items: center;
  text-align: center;
}

.separator::before,
.separator::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid #b6c6e3;
}

.separator:not(:empty)::before {
  margin-right: .25em;
}

.separator:not(:empty)::after {
  margin-left: .25em;
}
</style>
