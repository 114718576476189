<template>
  <div class="progress">
    <div class="progress-bar" role="progressbar" :aria-valuenow="progress" :aria-valuemin="0" :aria-valuemax="100" :data-progress="progress" :class="classesList"
         :style="{width: progress+'%'}">

    </div>
  </div>
</template>

<script>
export default {
  props: {
    progress: {
      required: true,
      type: Number,
    },
    color: String,
  },
  setup(props){
    let classesList = []
    if( props.color){
      classesList.push(`bg-${props.color}`)
    }
    return {
      classesList,
    }
  }
}
</script>
